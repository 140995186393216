.App {
  margin: 0 auto;
}

.App-header {
  padding: 10px;
  background-color: #d9dadd;
  min-height: 100vh;
}

.App-List {
  margin-top: 10px;
}
